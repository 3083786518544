import React, { useEffect } from 'react';
import { AppBar, makeStyles, Toolbar, Grid, Typography } from '@material-ui/core';
import { SignOutButton } from './SignOutButton';
import * as Constant from '../../constants/Constant';
import auth from '../../images/header/authendication.svg';
import { IProspectUsersDetails } from '../../models/accesscontrol/ProspectUsersDetails';
import { usePost } from '../../utils/apiHelper';

const useStyles = makeStyles({
    table: {
        minWidth: 650
    },
    root: {
        display: 'flex',
        justifyContent: 'center',
        textAlign: 'center',
        width: '100%',
        margin: '10px 0px'
    },
    image: {
        width: '60vw',
        height: '70vh',
        alignItems: "center",
    },
    MessageStyle: {
        fontSize: '21px',
        color: '#0136a1',
        marginTop: '5px'
    },
    MessageStyle1: {
        fontSize: '19px',
        color: '#0136a1',
        marginTop: '5px',
        fontStyle: 'italic'
    }
});

export const ProfileData: React.FC<{ userDetails: IProspectUsersDetails, isConfirmed: boolean }> = (props) => {
    const { userDetails, isConfirmed } = props;
    const classes = useStyles();

    // useEffect(() => {
    //     let request = {
    //         "userName": userDetails?.prospectEmailAddress,
    //         "organizationAdminEmailId": userDetails?.organizationAdminEmailId,
    //         "organizationPhoneNumber": userDetails?.organizationPhoneNumber,
    //         "organizationName": userDetails?.organizationName,
    //         "isNewDomainUser": userDetails?.isNewOrginization
    //     }
    //     userDetails?.isWebviewUser && !userDetails?.isNewOrginization ?
    //         usePost<any>("ProspectUsersAdminEmail", request).then((r) => {
    //         })
    //         : null
    // }, []);

    return (
        <React.Fragment>
            <AppBar position="static" style={{ backgroundColor: 'White' }}>
                <Toolbar>
                    <Grid container spacing={3}>
                        <Grid item xs={2}>
                            <img alt="ARM_Solutions Logo" width="160px" height="55px" src={Constant.ARMLogo} />
                        </Grid>
                        <Grid item xs={8} style={{ color: 'black', marginTop: '18px' }}>
                            <Typography align='center'>
                                Logged in as : <span style={{ color: 'blue' }}><b>{userDetails?.prospectEmailAddress}</b></span>
                            </Typography>
                        </Grid>
                        <Grid item xs={2}>
                            <SignOutButton />
                        </Grid>
                    </Grid>
                </Toolbar>
            </AppBar>
            <div className={classes.root}>
                <Grid container spacing={0} >
                    <Grid item xs={12}>
                        <img alt="ARM_Solutions Logo" src={auth} width="auto" height="auto" className={classes.image} />
                    </Grid>
                    {userDetails?.isClientInfoConfirmed || isConfirmed ?
                        <Grid item xs={12}>
                            <Typography variant='body2' className={classes.MessageStyle}>
                                <b>Wait for client services to onboard your organization and authorize WebView permission</b> <br />
                            </Typography>
                            <Typography variant='body2' className={classes.MessageStyle1}>
                                If you need any clarification or experiencing any difficulties, <br />please reach out &nbsp;
                                <a href="mailto:clientservices@armsolutions.com" style={{ textDecoration: 'none' }}><b>{Constant.serviceEmailID}</b></a>
                            </Typography>
                        </Grid>
                        : userDetails?.isNewOrginization && userDetails?.isMultiBranch != null ?
                            <Grid item xs={12}>
                                <Typography variant='body2' className={classes.MessageStyle}>
                                    <b>Successfully authenticated to Webview, client services team will contact you,<br />
                                        Once the appropriate permissions are assigned to you.</b>
                                </Typography>
                            </Grid>
                            : userDetails?.isNewOrginization && (userDetails?.isMultiBranch === null || userDetails?.isMultiBranch === undefined) ?
                                <Grid item xs={12}>
                                    <Typography variant='body2' className={classes.MessageStyle}>
                                        <b>Your account request is with our Client Services team. <br />You will receive an email once your setup form is ready to be filled out.</b> <br />
                                    </Typography>
                                    <Typography variant='body2' className={classes.MessageStyle1}>
                                        If you need any clarification or experiencing any difficulties, <br />please reach out &nbsp;
                                        <a href="mailto:clientservices@armsolutions.com" style={{ textDecoration: 'none' }}><b>{Constant.serviceEmailID}</b></a>
                                    </Typography>
                                </Grid>
                                :
                                <Grid item xs={12}>
                                    <Typography variant='body2' className={classes.MessageStyle}>
                                        <b>Successfully signed up to WebView. Your admin will contact you once your permissions are assigned.</b>
                                    </Typography>
                                </Grid>
                    }
                </Grid>
            </div>
        </React.Fragment>
    );
};

