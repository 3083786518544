import {
    AppBar, Backdrop, Button, Checkbox, Dialog, DialogActions, DialogContent, FormControl, FormControlLabel, Grid, Paper, Radio,
    RadioGroup,
    Snackbar, TableContainer, TextField, Toolbar, Typography
} from '@material-ui/core';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import React, { useEffect, useState } from 'react';
import PuffLoader from "react-spinners/PuffLoader";
// Icon
import CancelIcon from '@material-ui/icons/Cancel';
import CloseIcon from '@material-ui/icons/Close';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import DoneAllIcon from "@mui/icons-material/DoneAll";
import HowToRegIcon from '@mui/icons-material/HowToReg';
// Models
import { ARMLogo, EmailRegex, preventSpecialKeys } from '../../../constants/Constant';
import { IBranchNameList, INBBranchClientInformation, INewBusinessBranchDetails, IWebsiteUrl } from '../../../models/NBClientInformation/INBBranchClientInformation';
import { NBCompanyClientInformation } from '../../../models/NBClientInformation/NBCompanyClientInformation';
// Componants
import ListIcon from '@mui/icons-material/List';
import { IProspectUserDataList, IProspectUsersDetails } from '../../../models/accesscontrol/ProspectUsersDetails';
import { useGlobalState } from '../../../store/GlobalStore';
import { useFetch, usePost } from '../../../utils/apiHelper';
import Footer from '../../Footer/Footer';
import { Transition } from '../../GlobalStyles/DialogBoxTransition';
import { DialogTitleDelete, DialogTitleHeader } from '../../GlobalStyles/DialogStyle';
import LightTooltip from '../../GlobalStyles/LightTooltip';
import { useStyles } from './multiLocationCss';
import { IconButton, Tooltip } from '@mui/material';
import ErrorIcon from '@mui/icons-material/Error';

const MultiLocationDetails: React.FC<{ userRow: IProspectUsersDetails, onUpdate: () => void }> = (props) => {
    const { userRow, onUpdate } = props
    const preventBackSlash = "Backslash";
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [multiLocation, setMultiLocation] = useState(0);
    const [branchNameList, setBranchNameList] = useState<IBranchNameList[]>([]);
    const [multipleBranchList, setMultipleBranchList] = useState<INBBranchClientInformation>();
    const [getCompanyDetails, setGetCompanyDetails] = useState<NBCompanyClientInformation>();
    const [progressBar, setShowProgressBar] = useState(false);
    const [initiateOpen, setInitiateOpen] = React.useState(false);
    const [clientInfoOpen, setClientInfoOpen] = React.useState(false);
    const [EmailName, setEmailName] = useState(userRow?.prospectEmailAddress);
    const [Description, setDescription] = useState("");
    const [Emailerror, setEmailError] = useState("");
    const [isDisableEmail, setIsDisableEmail] = useState(false);
    const [isCorrectionUpadate, setIsCorrectionUpadate] = useState(false);
    const [isApproveData, setIsApproveData] = useState(false);
    const { state } = useGlobalState();
    const [usersData, setUsersData] = useState<IProspectUserDataList>();
    const [checked, setChecked] = useState(false);
    const [urlExist, setUrlExist] = useState<boolean>(false);
    const [urlSecure, setUrlSecure] = useState<boolean>(false);
    const [paymenturlExist, setPaymentUrlExist] = useState<boolean>(false);
    const [paymenturlSecure, setPaymentUrlSecure] = useState<boolean>(false);
    const [branchPaymenturlExist, setBranchPaymentUrlExist] = useState<boolean>(false);
    const [branchPaymentSecure, setBranchPaymentUrlSecure] = useState<boolean>(false);

    const PREFIX = 'https://';

    useEffect(() => {
        if (userRow?.prospectEmailAddress) {
            setEmailName(userRow.prospectEmailAddress);
        }
    }, [userRow?.prospectEmailAddress]);

    const handleValidateURL = (comp_Website) => {
        if (comp_Website !== null && comp_Website !== undefined) {
            let finalwebsiteURL = comp_Website?.includes(PREFIX) ? comp_Website : PREFIX + comp_Website;
            setShowProgressBar(true);
            (async () => {
                await useFetch<{ domainExists, isSecured }>(`Client/ValidateWebsiteURL?urlresult=${finalwebsiteURL}`).then((r) => {
                    setUrlExist(r?.data?.domainExists);
                    setUrlSecure(r?.data?.isSecured);
                })
            })().finally(() => {
                setShowProgressBar(false);
            })
        }
    }

    const handleCompanyPaymentValidateURL = (payment_Website) => {
        if (payment_Website !== null && payment_Website !== undefined) {
            let finalwebsiteURL = payment_Website?.includes(PREFIX) ? payment_Website : PREFIX + payment_Website;
            setShowProgressBar(true);
            (async () => {
                await useFetch<{ domainExists, isSecured }>(`Client/ValidateWebsiteURL?urlresult=${finalwebsiteURL}`).then((r) => {
                    setPaymentUrlExist(r?.data?.domainExists);
                    setPaymentUrlSecure(r?.data?.isSecured);
                })
            })().finally(() => {
                setShowProgressBar(false);
            })
        }
    }

    const handleBranchPaymentValidateURL = (payment_Website) => {
        if (payment_Website !== null && payment_Website !== undefined) {
            let finalPaymentwebsiteUrl = payment_Website?.includes(PREFIX) ? payment_Website : PREFIX + payment_Website;
            setShowProgressBar(true);
            (async () => {
                await useFetch<{ domainExists, isSecured }>(`Client/ValidateWebsiteURL?urlresult=${finalPaymentwebsiteUrl}`).then((r) => {
                    setBranchPaymentUrlExist(r?.data?.domainExists);
                    setBranchPaymentUrlSecure(r?.data?.isSecured);
                })
            })().finally(() => {
                setShowProgressBar(false);
            })
        }
    }

    const handleClickinitiateOpen = () => {
        setInitiateOpen(true);
    };

    const handlesetInitiateClose = () => {
        setInitiateOpen(false);
        setEmailName(userRow?.prospectEmailAddress);
        setDescription("");
        setEmailError("");
    };

    const handlesetClientInfoOpen = () => {
        setClientInfoOpen(true);
        getCompanyName();
    };

    const handlesetClientInfoClose = () => {
        setClientInfoOpen(false);
    };

    const getAllBranchDetails = () => {
        let newBranchInformation = [];
        (async () => {
            let request = {
                "userId": userRow?.prospectUserId,
                "emailAddress": userRow?.prospectEmailAddress,
                "branchId": null
            }
            const result = await usePost<{ branchInformation: INewBusinessBranchDetails[] }>("Client/GetBranchDetailsBasedUser", request);
            result?.data?.branchInformation?.forEach((a) => {
                let branchDetails: IBranchNameList = {
                    branchId: a?.branchID,
                    branchName: a?.branch_Location_Name
                }
                newBranchInformation.push(branchDetails)
            })

        })().finally(() => {
            setBranchNameList(newBranchInformation);
        })
    }

    const getBranchClientInformation = (branchId: number) => {
        setShowProgressBar(true);
        (async () => {
            let request = {
                "userId": userRow?.prospectUserId,
                "emailAddress": userRow?.prospectEmailAddress,
                "branchId": branchId
            }
            await usePost<{ branchInformation: INewBusinessBranchDetails[] }>("Client/GetBranchDetailsBasedUser", request).then((result) => {
                setMultipleBranchList(result?.data?.branchInformation[0]);
                handleBranchPaymentValidateURL(result?.data?.branchInformation[0]?.payment_Website)
            })
        })().finally(() => {
            setShowProgressBar(false);
            setMultiLocation(branchId);
        })
    }

    const handleClickOpen = () => {
        setShowProgressBar(true);
        (async () => {
            let request = {
                "userId": userRow?.prospectUserId,
                "emailAddress": userRow?.prospectEmailAddress,
            }
            await usePost<{ companyInformation: NBCompanyClientInformation }>("Client/getCompanyDetailsBasedUser", request).then((result) => {
                setGetCompanyDetails(result?.data.companyInformation[0]);
                handleValidateURL(result?.data.companyInformation[0]?.comp_Website);
                handleCompanyPaymentValidateURL(result?.data.companyInformation[0]?.collection_Notice_Payment_website);
            })
            getAllBranchDetails();
            setOpen(true);
        })().finally(() => {
            setShowProgressBar(false);
        })
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleReinitiateOk = () => {
        setShowProgressBar(true);
        (async () => {
            let request = {
                "userId": userRow?.prospectUserId,
                "isClientInfoConfirmed": false,
                "id": state.userAccessContext?.id,
                "message": Description,
                "emailSentTo": EmailName
            }
            await usePost<any>("Client/ConfirmAllClientData", request).then((r) => {
                if (r?.status === 200 || r?.status === 202) {
                    handleSendEmailtoUser();
                }
                setIsCorrectionUpadate(true);
            }).finally(() => {
                onUpdate();
            })
        })().finally(() => {
            setShowProgressBar(false);
            setOpen(false);
            handlesetInitiateClose();
        })
    }

    const handleClickApproveClientData = () => {
        setShowProgressBar(true);
        (async () => {
            let request = {
                "prospectUserId": userRow?.prospectUserId,
                "isClientOnboard": true,
                "isClientSetup": null,
                "userId": state.userAccessContext?.id,
                "message": null,
                "emailSentTo": null
            }
            await usePost<any>("User/VerifyOnboardClientSetup", request).then((r) => {
                handleClickClientVerified();
                setIsApproveData(true);
            }).finally(() => {
                setShowProgressBar(false);
            });
            onUpdate();
        })().finally(() => {
            setClientInfoOpen(false);
            setOpen(false);
        })
    }

    const getCompanyName = () => {
        (async () => {
            let request = {
                "userId": userRow?.prospectUserId,
                "emailAddress": userRow?.prospectEmailAddress
            }
            await usePost<{ companyInformation: NBCompanyClientInformation }>("Client/GetCompanyDetailsBasedUser", request);
        })().finally(() => {
        })
    }

    const handleSendEmailtoUser = () => {
        //Email will trigger when send a correction to user
        let sendEmail = {
            "organizationName": userRow?.organizationName,
            "userName": EmailName,
            "description": Description
        }
        usePost<any>("SendCorrectionsEmailtoUser", sendEmail);
    }

    const handleClickClientVerified = () => {
        (async () => {
            await useFetch<{ prospectUserDetailsByIdResult: IProspectUserDataList[] }>(`User/GetProspectUserDetailsById?prospectUserId=${userRow?.prospectUserId}`).then((result) => {
                setUsersData(result?.data?.prospectUserDetailsByIdResult[0]);
                if (result?.status === 200 || result?.status === 202) {
                }
            })
        })().finally(() => {
        })
    }

    useEffect(() => {
        if (usersData?.isClientOnboarded && usersData?.isDocumentUploaded) {
            SendEmailtoDataOpsTeam(); // Email will trigger when admin approves the client information
        }
    }, [usersData]);

    const SendEmailtoDataOpsTeam = () => {
        // Email will trigger when a admin approved the client information
        let sendEmail = {
            "userName": userRow?.prospectEmailAddress,
            "OrganizationName": getCompanyDetails?.comp_Name
        }
        usePost<any>("ClientInfoVerified", sendEmail);
    }

    const preventSpecialCharacters = (e) => {
        if (preventSpecialKeys.includes(e.key)) {
            e.preventDefault();
        } else if (e.code === preventBackSlash) {
            e.preventDefault();
        }
    };

    function handleOnBlur(event) {
        var emails = event.target.value;
        emails = emails?.split(",");
        let lastIndex = emails?.length - 1;
        if ((!EmailRegex.test(emails[lastIndex]))) {
            setEmailError("Formatting error:make sure all email addresses are correct and separated only by commas.");
            setIsDisableEmail(true);
        }
        else {
            setEmailError('');
            setIsDisableEmail(false);
        }
    }

    const CreditDetails = [
        { title: 'Visa' },
        { title: 'Mastercard' },
        { title: 'Discover' },
        { title: 'American Express' },
        { title: 'None' },
    ];

    function Alert(props: AlertProps) {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }

    const closeSnackbar = (event?: React.SyntheticEvent, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setIsCorrectionUpadate(false);
        setIsApproveData(false);
    };

    const handleChange = (event) => {
        setChecked(event.target.checked);
    };

    return (
        <div >
            {userRow?.isNewOrginization ?
                <Button variant='contained' size="small" color='primary'
                    className={userRow?.isClientOnboarded ? classes.documentApproved : classes.documentNotApproved} startIcon={userRow?.isClientOnboarded ? <DoneAllIcon /> : <ListIcon />} onClick={() => { handleClickOpen() }}>
                    {userRow?.isClientOnboarded ?
                        <span>CLIENT VERIFIED</span>
                        :
                        <span>VERIFY CLIENT</span>
                    }
                </Button>
                : null
            }
            <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition} className={classes.dialog}>
                <AppBar position="fixed" className={classes.appBar} >
                    <Toolbar className={classes.toolbarStyle}>
                        <Grid container spacing={0}>
                            <Grid item xs={4} sm={4} md={2} lg={2} className={classes.menuButton}>
                                <img alt="ARM_Solutions" className={classes.menuButton1} src={ARMLogo} />
                            </Grid>
                            <Grid item xs={8} sm={8} md={6} lg={6} >
                                <Typography variant="h6" className={classes.title1}>
                                    <b>CLIENT INFORMATION DETAILS</b>
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={12} md={4} lg={4} className={classes.buttonDisplay} >
                                {!userRow?.isClientInfoConfirmed ?
                                    <LightTooltip title="Client confirmation is pending by the user. If Already confirmed please refresh the page.">
                                        <InfoOutlinedIcon className={classes.informationIconStyle} />
                                    </LightTooltip>
                                    : null
                                }
                                <Button variant='contained' size="small" color="primary" className={classes.sendButton} disabled={userRow?.isClientOnboarded || userRow?.isPermissionUpdated || !userRow?.isClientInfoConfirmed} startIcon={<DoneAllIcon />} onClick={handleClickinitiateOpen}>
                                    SEND CORRECTIONS
                                </Button>
                                <Button variant='contained' size="small" color="primary" className={classes.clientButton} disabled={userRow?.isClientOnboarded || userRow?.isPermissionUpdated || !userRow?.isClientInfoConfirmed} startIcon={<HowToRegIcon />} onClick={() => { handlesetClientInfoOpen() }}>
                                    APPROVE CLIENT DATA
                                </Button>
                                <IconButton edge="end" color="inherit" onClick={() => handleClose()} aria-label="close" style={{ padding: '5px' }}>
                                    <CloseIcon className={classes.closeIcon} />
                                </IconButton>
                            </Grid>
                        </Grid>
                    </Toolbar>
                </AppBar>

                <div className="wrapper">
                    <div className="sidebar">
                        <ul className={classes.listStyle}>
                            <li className='row' id={`${multiLocation == 0 ? "active" : ""}`} onClick={(_) => setMultiLocation(0)} >
                                <div id="title" >
                                    {getCompanyDetails?.comp_Name?.length ?
                                        getCompanyDetails?.comp_Name
                                        :
                                        <b>COMPANY NAME</b>
                                    }
                                </div>
                            </li>

                            {branchNameList.map((row, index) => (
                                <div id={`${row?.branchId}`} className={`${multiLocation == row?.branchId ? classes.activeStyle : ""} ${"row"}`} onClick={() => { getBranchClientInformation(row?.branchId); }}>
                                    <LightTooltip id={`${row?.branchId}`} title={row?.branchName}>
                                        <Typography variant='h6' className={classes.textDisplay} id={`${row?.branchId}`} onClick={() => { getBranchClientInformation(row?.branchId); }}>
                                            <div id="title" key={row?.branchId}>
                                                <b>{row?.branchName}</b>
                                            </div>
                                        </Typography>
                                    </LightTooltip>
                                </div>
                            ))}
                        </ul>
                    </div >
                    <div className="main_content">
                        <Backdrop className={classes.backdrop} open={progressBar}>
                            <PuffLoader size={80} color={"white"} speedMultiplier={1} />
                        </Backdrop>
                        <Grid container spacing={0} >
                            <Grid item xs={12} sm={12} md={12} lg={12} >
                                {multiLocation == 0 ?
                                    <div>
                                        <TableContainer className={`${classes.pageLayout} ${"scrollbox"} ${"on-scrollbar"}`} >
                                            <Grid container component={Paper} spacing={0} style={{ padding: '10px' }}>
                                                <Grid xs={12} sm={12} md={12} lg={12} >
                                                    <Typography variant='h6' className={classes.headStyle}>
                                                        <b>COMPANY INFORMATION</b>
                                                    </Typography>
                                                </Grid>
                                                <Grid xs={12} sm={12} md={6} lg={4}>
                                                    <Typography variant="body1" className={classes.textfieldColor}>
                                                        Company Name <span className="required">*</span>
                                                    </Typography>
                                                    <TextField
                                                        name="comp_Name"
                                                        placeholder="Company Name"
                                                        value={getCompanyDetails?.comp_Name}
                                                        className={classes.textfieldStyle}
                                                        InputProps={{
                                                            readOnly: true,
                                                            classes: {
                                                                root: classes.blueBorderBottom,
                                                                notchedOutline: classes.noBorder
                                                            },
                                                        }}
                                                        margin="dense" variant="outlined"
                                                    />
                                                </Grid>
                                                <Grid xs={12} sm={12} md={6} lg={4}>
                                                    <Typography variant="body1" className={classes.textfieldColor}>
                                                        Company Phone Number <span className="required">*</span>
                                                    </Typography>
                                                    <TextField
                                                        name="comp_PhoneNumber"
                                                        value={getCompanyDetails?.comp_PhoneNumber}
                                                        placeholder="Company Phone Number"
                                                        className={classes.phoneNumberField}
                                                        InputProps={{
                                                            readOnly: true,
                                                            classes: {
                                                                root: classes.blueBorderBottom,
                                                                notchedOutline: classes.noBorder
                                                            },
                                                        }}
                                                        margin="dense" variant="outlined"
                                                    />
                                                    <TextField
                                                        name="comp_PhoneNumberExt"
                                                        placeholder="ext."
                                                        value={getCompanyDetails?.comp_PhoneNumberExt}
                                                        className={classes.phoneNumberExt}
                                                        InputProps={{
                                                            readOnly: true,
                                                            classes: {
                                                                root: classes.blueBorderBottom,
                                                                notchedOutline: classes.noBorder
                                                            },
                                                            inputProps: {
                                                                maxLength: 10 // Replace 10 with your desired maximum length
                                                            }
                                                        }}
                                                        margin="dense" variant="outlined"
                                                    />
                                                </Grid>
                                                <Grid xs={12} sm={12} md={6} lg={4}>
                                                    <Typography variant="body1" className={classes.textfieldColor}>
                                                        Company Fax
                                                    </Typography>
                                                    <TextField
                                                        name="comp_Fax"
                                                        placeholder="Company Fax"
                                                        value={getCompanyDetails?.comp_Fax}
                                                        className={classes.textfieldStyle}
                                                        InputProps={{
                                                            readOnly: true,
                                                            classes: {
                                                                root: classes.blueBorderBottom,
                                                                notchedOutline: classes.noBorder
                                                            },
                                                        }}
                                                        margin="dense" variant="outlined"
                                                    />
                                                </Grid>
                                                <Grid xs={12} sm={12} md={6} lg={4}>
                                                    <Typography variant="body1" className={classes.textfieldColor}>
                                                        Company Physical Address - Street <span className="required">*</span>
                                                    </Typography>
                                                    <TextField
                                                        name="comp_phy_Address_Street"
                                                        value={getCompanyDetails?.comp_phy_Address_Street}
                                                        placeholder="Company Physical Address - Street"
                                                        className={classes.textfieldStyle}
                                                        InputProps={{
                                                            readOnly: true,
                                                            classes: {
                                                                root: classes.blueBorderBottom,
                                                                notchedOutline: classes.noBorder
                                                            },
                                                        }}
                                                        margin="dense" variant="outlined"
                                                    />
                                                </Grid>
                                                <Grid xs={12} sm={12} md={6} lg={4}>
                                                    <Typography variant="body1" className={classes.textfieldColor}>
                                                        Company Physical Address - City <span className="required">*</span>
                                                    </Typography>
                                                    <TextField
                                                        name="comp_phy_Address_City"
                                                        value={getCompanyDetails?.comp_phy_Address_City}
                                                        placeholder="Company Physical Address - City"
                                                        className={classes.textfieldStyle}
                                                        InputProps={{
                                                            readOnly: true,
                                                            classes: {
                                                                root: classes.blueBorderBottom,
                                                                notchedOutline: classes.noBorder
                                                            },
                                                        }}
                                                        margin="dense" variant="outlined"
                                                    />
                                                </Grid>
                                                <Grid xs={12} sm={12} md={6} lg={4}>
                                                    <Typography variant="body1" className={classes.textfieldColor}>
                                                        Company Physical Address - State <span className="required">*</span>
                                                    </Typography>
                                                    <TextField
                                                        name="comp_phy_Address_State"
                                                        value={getCompanyDetails?.comp_phy_Address_State}
                                                        placeholder="Company Physical Address - State"
                                                        className={classes.textfieldStyle}
                                                        InputProps={{
                                                            readOnly: true,
                                                            classes: {
                                                                root: classes.blueBorderBottom,
                                                                notchedOutline: classes.noBorder
                                                            },
                                                        }}
                                                        margin="dense" variant="outlined"
                                                    />
                                                </Grid>
                                                <Grid xs={12} sm={12} md={6} lg={4}>
                                                    <Typography variant="body1" className={classes.textfieldColor}>
                                                        Company Physical Address - Zip Code <span className="required">*</span>
                                                    </Typography>
                                                    <TextField
                                                        name="comp_phy_Address_Zip"
                                                        value={getCompanyDetails?.comp_phy_Address_Zip}
                                                        placeholder="Company Physical Address - Zip Code"
                                                        className={classes.textfieldStyle}
                                                        InputProps={{
                                                            readOnly: true,
                                                            classes: {
                                                                root: classes.blueBorderBottom,
                                                                notchedOutline: classes.noBorder
                                                            },
                                                        }}
                                                        margin="dense" variant="outlined"
                                                    />
                                                </Grid>
                                                <Grid xs={12} sm={12} md={6} lg={4}>
                                                    <Typography variant="body1" className={classes.textfieldColor}>
                                                        Company Mailing Address - Street <span className="required">*</span>
                                                    </Typography>
                                                    <TextField
                                                        name="comp_Mailing_Adress_Street"
                                                        value={getCompanyDetails?.comp_Mailing_Adress_Street}
                                                        placeholder="Company Mailing Address - Street"
                                                        className={classes.textfieldStyle}
                                                        InputProps={{
                                                            readOnly: true,
                                                            classes: {
                                                                root: classes.blueBorderBottom,
                                                                notchedOutline: classes.noBorder
                                                            },
                                                        }}
                                                        margin="dense" variant="outlined"
                                                    />
                                                </Grid>
                                                <Grid xs={12} sm={12} md={6} lg={4}>
                                                    <Typography variant="body1" className={classes.textfieldColor}>
                                                        Company Mailing Address - City <span className="required">*</span>
                                                    </Typography>
                                                    <TextField
                                                        name="comp_Mailing_Adress_City"
                                                        value={getCompanyDetails?.comp_Mailing_Adress_City}
                                                        placeholder="Company Mailing Address - City"
                                                        className={classes.textfieldStyle}
                                                        InputProps={{
                                                            readOnly: true,
                                                            classes: {
                                                                root: classes.blueBorderBottom,
                                                                notchedOutline: classes.noBorder
                                                            },
                                                        }}
                                                        margin="dense" variant="outlined"
                                                    />
                                                </Grid>
                                                <Grid xs={12} sm={12} md={6} lg={4}>
                                                    <Typography variant="body1" className={classes.textfieldColor}>
                                                        Company Mailing Address - State <span className="required">*</span>
                                                    </Typography>
                                                    <TextField
                                                        name="comp_Mailing_Adress_State"
                                                        value={getCompanyDetails?.comp_Mailing_Adress_State}
                                                        placeholder="Company Mailing Address - State"
                                                        className={classes.textfieldStyle}
                                                        InputProps={{
                                                            readOnly: true,
                                                            classes: {
                                                                root: classes.blueBorderBottom,
                                                                notchedOutline: classes.noBorder
                                                            },
                                                        }}
                                                        margin="dense" variant="outlined"
                                                    />
                                                </Grid>
                                                <Grid xs={12} sm={12} md={6} lg={4}>
                                                    <Typography variant="body1" className={classes.textfieldColor}>
                                                        Company Mailing Address - ZipCode <span className="required">*</span>
                                                    </Typography>
                                                    <TextField
                                                        name="comp_Mailing_Adress_Zip"
                                                        value={getCompanyDetails?.comp_Mailing_Adress_Zip}
                                                        placeholder="Company Mailing Address - ZipCode"
                                                        className={classes.textfieldStyle}
                                                        InputProps={{
                                                            readOnly: true,
                                                            classes: {
                                                                root: classes.blueBorderBottom,
                                                                notchedOutline: classes.noBorder
                                                            },
                                                        }}
                                                        margin="dense" variant="outlined"
                                                    />
                                                </Grid>
                                                <Grid xs={12} sm={12} md={6} lg={4}>
                                                    <Grid container spacing={0}>
                                                        <Grid item xs={5}>
                                                            <Typography variant="body1" className={classes.textfieldColor}>
                                                                Company Website
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={7}>
                                                            {!getCompanyDetails?.comp_Website ? (
                                                                <Tooltip title="The URL is empty." style={{ marginBottom: '0px' }}>
                                                                    <IconButton>
                                                                        <ErrorIcon fontSize='small' />
                                                                    </IconButton>
                                                                </Tooltip>
                                                            ) : !urlExist ? (
                                                                <Tooltip title="This domain does not exist." style={{ marginBottom: '0px' }}>
                                                                    <IconButton>
                                                                        <ErrorIcon />
                                                                    </IconButton>
                                                                </Tooltip>
                                                            ) : !urlSecure ? (
                                                                <Tooltip title="The domain exists but the URL is not secured." style={{ marginBottom: '0px' }}>
                                                                    <IconButton>
                                                                        <ErrorIcon />
                                                                    </IconButton>
                                                                </Tooltip>
                                                            ) : (
                                                                <Tooltip title="The domain exists and the URL is secured." style={{ marginBottom: '0px' }}>
                                                                    <IconButton>
                                                                        <ErrorIcon />
                                                                    </IconButton>
                                                                </Tooltip>
                                                            )}
                                                        </Grid>

                                                        <Grid item xs={12}>
                                                            <TextField
                                                                name="comp_Website"
                                                                value={getCompanyDetails?.comp_Website}
                                                                placeholder="Company Website"
                                                                className={classes.textfieldStyle}
                                                                InputProps={{
                                                                    readOnly: true,
                                                                    classes: {
                                                                        root: classes.blueBorderBottom,
                                                                        notchedOutline: classes.noBorder
                                                                    },
                                                                }}
                                                                margin="dense" variant="outlined"
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid xs={12} sm={12} md={6} lg={4}>
                                                    <Typography variant="body1" className={classes.textfieldColor}>
                                                        Corporate Contact Person <span className="required">*</span>
                                                    </Typography>
                                                    <TextField
                                                        name="crop_Contact_Person"
                                                        value={getCompanyDetails?.crop_Contact_Person}
                                                        placeholder="Corporate Contact Person"
                                                        className={classes.textfieldStyle}
                                                        InputProps={{
                                                            readOnly: true,
                                                            classes: {
                                                                root: classes.blueBorderBottom,
                                                                notchedOutline: classes.noBorder
                                                            },
                                                        }}
                                                        margin="dense" variant="outlined"
                                                    />
                                                </Grid>
                                                <Grid xs={12} sm={12} md={6} lg={4}>
                                                    <Typography variant="body1" className={classes.textfieldColor}>
                                                        Corporate Contact Title <span className="required">*</span>
                                                    </Typography>
                                                    <TextField
                                                        name="crop_Contact_Title"
                                                        value={getCompanyDetails?.crop_Contact_Title}
                                                        placeholder="Corporate Contact Title"
                                                        className={classes.textfieldStyle}
                                                        InputProps={{
                                                            readOnly: true,
                                                            classes: {
                                                                root: classes.blueBorderBottom,
                                                                notchedOutline: classes.noBorder
                                                            },
                                                        }}
                                                        margin="dense" variant="outlined"
                                                    />
                                                </Grid>
                                                <Grid xs={12} sm={12} md={6} lg={4}>
                                                    <Typography variant="body1" className={classes.textfieldColor}>
                                                        Corporate Contact Phone <span className="required">*</span>
                                                    </Typography>
                                                    <TextField
                                                        name="crop_Contact_Phone"
                                                        value={getCompanyDetails?.crop_Contact_Phone}
                                                        placeholder="Corporate Contact Phone"
                                                        className={classes.phoneNumberField}
                                                        InputProps={{
                                                            readOnly: true,
                                                            classes: {
                                                                root: classes.blueBorderBottom,
                                                                notchedOutline: classes.noBorder
                                                            },
                                                        }}
                                                        margin="dense" variant="outlined"
                                                    />
                                                    <TextField
                                                        name="crop_Contact_PhoneExt"
                                                        placeholder="Ext."
                                                        value={getCompanyDetails?.crop_Contact_PhoneExt}
                                                        className={classes.phoneNumberExt}
                                                        InputProps={{
                                                            readOnly: true,
                                                            classes: {
                                                                root: classes.blueBorderBottom,
                                                                notchedOutline: classes.noBorder
                                                            },
                                                            inputProps: {
                                                                maxLength: 10 // Replace 10 with your desired maximum length
                                                            }
                                                        }}
                                                        margin="dense" variant="outlined"
                                                    />
                                                </Grid>
                                                <Grid xs={12} sm={12} md={6} lg={4}>
                                                    <Typography variant="body1" className={classes.textfieldColor}>
                                                        Corporate Contact Email <span className="required">*</span>
                                                    </Typography>
                                                    <TextField
                                                        name="crop_Contact_Email"
                                                        value={getCompanyDetails?.crop_Contact_Email}
                                                        placeholder="Corporate Contact Email"
                                                        className={classes.textfieldStyle}
                                                        InputProps={{
                                                            readOnly: true,
                                                            classes: {
                                                                root: classes.blueBorderBottom,
                                                                notchedOutline: classes.noBorder
                                                            },
                                                        }}
                                                        margin="dense" variant="outlined"
                                                    />
                                                </Grid>
                                            </Grid>
                                            {userRow?.isMultiBranch ? null :
                                                <Grid container component={Paper} spacing={0} className={classes.paddingStyle}>
                                                    <Grid xs={12} sm={12} md={12} lg={12} >
                                                        <Typography variant='h6' className={classes.headStyle}>
                                                            <b>DAY TO DAY CONTACT INFORMATION</b>
                                                        </Typography>
                                                    </Grid>
                                                    <Grid xs={12} sm={12} md={6} lg={4}>
                                                        <Typography variant="body1" className={classes.textfieldColor}>
                                                            Day to Day Contact Name <span className="required">*</span>
                                                        </Typography>
                                                        <TextField
                                                            name="day_to_Day_Contact"
                                                            value={getCompanyDetails?.day_to_Day_Contact}
                                                            placeholder="Day to Day Contact Name"
                                                            className={classes.textfieldStyle}
                                                            InputProps={{
                                                                readOnly: true,
                                                                classes: {
                                                                    root: classes.blueBorderBottom,
                                                                    notchedOutline: classes.noBorder
                                                                },
                                                            }}
                                                            margin="dense" variant="outlined"
                                                        />
                                                    </Grid>
                                                    <Grid xs={12} sm={12} md={6} lg={4}>
                                                        <Typography variant="body1" className={classes.textfieldColor}>
                                                            Day to Day Contact Title <span className="required">*</span>
                                                        </Typography>
                                                        <TextField
                                                            name="day_to_Day_Contact_Title"
                                                            value={getCompanyDetails?.day_to_Day_Contact_Title}
                                                            placeholder="Day to Day Contact Title"
                                                            className={classes.textfieldStyle}
                                                            InputProps={{
                                                                readOnly: true,
                                                                classes: {
                                                                    root: classes.blueBorderBottom,
                                                                    notchedOutline: classes.noBorder
                                                                },
                                                            }}
                                                            margin="dense" variant="outlined"
                                                        />
                                                    </Grid>
                                                    <Grid xs={12} sm={12} md={6} lg={4}>
                                                        <Typography variant="body1" className={classes.textfieldColor}>
                                                            Day to Day Contact Phone Number <span className="required">*</span>
                                                        </Typography>
                                                        <TextField
                                                            name="day_to_Day_Contact_Phone"
                                                            value={getCompanyDetails?.day_to_Day_Contact_Phone}
                                                            placeholder="Day to Day Contact Phone Number"
                                                            className={classes.phoneNumberField}
                                                            InputProps={{
                                                                readOnly: true,
                                                                classes: {
                                                                    root: classes.blueBorderBottom,
                                                                    notchedOutline: classes.noBorder
                                                                },
                                                            }}
                                                            margin="dense" variant="outlined"
                                                        />
                                                        <TextField
                                                            name="day_to_Day_Contact_PhoneExt"
                                                            placeholder="Ext."
                                                            value={getCompanyDetails?.day_to_Day_Contact_PhoneExt}
                                                            className={classes.phoneNumberExt}
                                                            InputProps={{
                                                                readOnly: true,
                                                                classes: {
                                                                    root: classes.blueBorderBottom,
                                                                    notchedOutline: classes.noBorder
                                                                },
                                                                inputProps: {
                                                                    maxLength: 10 // Replace 10 with your desired maximum length
                                                                }
                                                            }}
                                                            margin="dense" variant="outlined"
                                                        />
                                                    </Grid>
                                                    <Grid xs={12} sm={12} md={6} lg={4}>
                                                        <Typography variant="body1" className={classes.textfieldColor}>
                                                            Day to Day Contact Email <span className="required">*</span>
                                                        </Typography>
                                                        <TextField
                                                            name="day_to_Day_Contact_Email"
                                                            value={getCompanyDetails?.day_to_Day_Contact_Email}
                                                            placeholder="Day to Day Contact Email"
                                                            className={classes.textfieldStyle}
                                                            InputProps={{
                                                                readOnly: true,
                                                                classes: {
                                                                    root: classes.blueBorderBottom,
                                                                    notchedOutline: classes.noBorder
                                                                },
                                                            }}
                                                            margin="dense" variant="outlined"
                                                        />
                                                    </Grid>
                                                </Grid>
                                            }
                                            <Grid container component={Paper} spacing={0} className={classes.paddingStyle}>
                                                <Grid xs={12} sm={12} md={12} lg={12} >
                                                    <Typography variant='h6' className={classes.headStyle}>
                                                        <b>GENERAL COLLECTION SET-UP INFORMATION</b>
                                                    </Typography>
                                                </Grid>
                                                <Grid xs={12} sm={12} md={6} lg={4}>
                                                    <Typography variant="body1" className={classes.textfieldColor}>
                                                        Number of Active Customers <span className="required">*</span>
                                                    </Typography>
                                                    <TextField
                                                        name="numberof_Active_Customer"
                                                        value={getCompanyDetails?.numberof_Active_Customer}
                                                        placeholder="Number of Active Customers"
                                                        className={classes.textfieldStyle}
                                                        InputProps={{
                                                            readOnly: true,
                                                            classes: {
                                                                root: classes.blueBorderBottom,
                                                                notchedOutline: classes.noBorder
                                                            },
                                                        }}
                                                        margin="dense" variant="outlined"
                                                    />
                                                </Grid>
                                                <Grid xs={12} sm={12} md={6} lg={4}>
                                                    <Typography variant="body1" className={classes.textfieldColor}>
                                                        Estimated Number of Monthly Placements <span className="required">*</span>
                                                    </Typography>
                                                    <TextField
                                                        name="est_Monthly_Placement"
                                                        value={getCompanyDetails?.est_Monthly_Placement}
                                                        placeholder="Estimated Number of Monthly Placements"
                                                        className={classes.textfieldStyle}
                                                        InputProps={{
                                                            readOnly: true,
                                                            classes: {
                                                                root: classes.blueBorderBottom,
                                                                notchedOutline: classes.noBorder
                                                            },
                                                        }}
                                                        margin="dense" variant="outlined"
                                                    />
                                                </Grid>
                                                <Grid xs={12} sm={12} md={6} lg={4}>
                                                    <Typography variant="body1" className={classes.textfieldColor}>
                                                        Software (ex. PestPac, RCC, CORE, QuickBooks) <span className="required">*</span>
                                                    </Typography>
                                                    <TextField
                                                        name="software_Using" variant="outlined"
                                                        value={getCompanyDetails?.software_Using}
                                                        className={classes.textfieldStyle}
                                                        InputProps={{
                                                            readOnly: true,
                                                            classes: {
                                                                root: classes.blueBorderBottom,
                                                                notchedOutline: classes.noBorder
                                                            },
                                                        }}
                                                        margin="dense"
                                                    />
                                                </Grid>
                                                <Grid xs={12} sm={12} md={6} lg={4}>
                                                    <Typography variant="body1" className={classes.textfieldColor}>
                                                        If you chose other, what software do you use?
                                                    </Typography>
                                                    <TextField
                                                        disabled={getCompanyDetails?.software_Using != "Other"}
                                                        name="other_Software"
                                                        value={getCompanyDetails?.other_Software}
                                                        placeholder="If you chose other, what software do you use?"
                                                        className={classes.textfieldStyle}
                                                        InputProps={{
                                                            readOnly: true,
                                                            classes: {
                                                                root: classes.blueBorderBottom,
                                                                notchedOutline: classes.noBorder
                                                            },
                                                        }}
                                                        margin="dense" variant="outlined"
                                                    />
                                                </Grid>
                                                <Grid xs={12} sm={12} md={12} lg={8}>
                                                    <Typography variant="body1" className={classes.textfieldColor}>
                                                        Do you authorize A.R.M. to settle Contingency accounts that are $500 or less for 75% of their balance? <span className="required">*</span>
                                                    </Typography>
                                                    <FormControl component="fieldset">
                                                        <RadioGroup aria-label="gender" name="authorize_ARM" className={classes.radioGroup}>
                                                            <FormControlLabel value="Yes" name="authorize_ARM" control={<Radio />} label="Yes" checked={getCompanyDetails?.authorize_ARM === true} />
                                                            <FormControlLabel value="No" name="authorize_ARM" control={<Radio />} label="No" checked={getCompanyDetails?.authorize_ARM === false} />
                                                        </RadioGroup>
                                                    </FormControl>
                                                </Grid>
                                                <Grid container spacing={2}>
                                                <Grid xs={12} sm={12} style={{display:'flex'}}>
                                                    <Grid xs={12} sm={12} md={6} lg={4} >
                                                        <Typography variant="body1" className={classes.textfieldColor}>
                                                            Industry/Service <span className="required">*</span>
                                                        </Typography>
                                                        <TextField
                                                        name="industryServices" variant="outlined"
                                                        value={getCompanyDetails?.industryServices}
                                                        className={classes.textfieldStyle}
                                                        InputProps={{
                                                            readOnly: true,
                                                            classes: {
                                                                root: classes.blueBorderBottom,
                                                                notchedOutline: classes.noBorder
                                                            },
                                                        }}
                                                        margin="dense"
                                                    />
                                                    </Grid>
                                                    <Grid xs={12} sm={12} md={6} lg={4}>
                                                    <Typography variant="body1" className={classes.textfieldColor}>
                                                        If you chose other, Please enter your industry
                                                    </Typography>
                                                    <TextField
                                                        disabled={getCompanyDetails?.industryServices != "Other"}
                                                        name="other_Industry"
                                                        className={classes.textfieldStyle}
                                                        inputProps={{ maxlength: 100 }}
                                                        value={getCompanyDetails?.other_Industry}
                                                        placeholder="If you chose other, what software do you use?"
                                                        InputProps={{
                                                            classes: {
                                                                root: classes.blueBorderBottom,
                                                                notchedOutline: classes.noBorder
                                                            },
                                                        }}
                                                        margin="dense" variant="outlined"
                                                    />
                                                </Grid>
                                                </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid container component={Paper} spacing={0} className={classes.paddingStyle}>
                                                <Grid xs={12} sm={12} md={12} lg={12} >
                                                    <Typography variant='h6' className={classes.headStyle}>
                                                        <b>COLLECTION SETUP INFORMATION</b>
                                                    </Typography>
                                                </Grid>
                                                <Grid xs={12} sm={12} md={6} lg={4}>
                                                    <Typography variant="body1" className={classes.textfieldColor}>
                                                        Company Name to Display on Collection Notices <span className="required">*</span>
                                                    </Typography>
                                                    <TextField
                                                        name="company_Display_on_Collection_Notices"
                                                        value={getCompanyDetails?.company_Display_on_Collection_Notices}
                                                        placeholder="Company Name to Display on Collection Notices"
                                                        className={classes.textfieldStyle}
                                                        InputProps={{
                                                            readOnly: true,
                                                            classes: {
                                                                root: classes.blueBorderBottom,
                                                                notchedOutline: classes.noBorder
                                                            },
                                                        }}
                                                        margin="dense" variant="outlined"
                                                    />
                                                </Grid>
                                                <Grid xs={12} sm={12} md={6} lg={4}>
                                                    <Typography variant="body1" className={classes.textfieldColor}>
                                                        Phone Number for Collection Notices <span className="required">*</span>
                                                    </Typography>
                                                    <TextField
                                                        name="collection_Notice_Phone"
                                                        value={getCompanyDetails?.collection_Notice_Phone}
                                                        placeholder="Phone Number for Collection Notices"
                                                        className={classes.phoneNumberField}
                                                        InputProps={{
                                                            readOnly: true,
                                                            classes: {
                                                                root: classes.blueBorderBottom,
                                                                notchedOutline: classes.noBorder
                                                            },
                                                        }}
                                                        margin="dense" variant="outlined"
                                                    />
                                                    <TextField
                                                        name="collection_Notice_PhoneExt"
                                                        placeholder="Ext."
                                                        value={getCompanyDetails?.collection_Notice_PhoneExt}
                                                        className={classes.phoneNumberExt}
                                                        InputProps={{
                                                            readOnly: true,
                                                            classes: {
                                                                root: classes.blueBorderBottom,
                                                                notchedOutline: classes.noBorder
                                                            },
                                                            inputProps: {
                                                                maxLength: 10 // Replace 10 with your desired maximum length
                                                            }
                                                        }}
                                                        margin="dense" variant="outlined"
                                                    />
                                                </Grid>
                                                <Grid xs={12} sm={12} md={6} lg={4}>
                                                    <Typography variant="body1" className={classes.textfieldColor}>
                                                        Remit Address for Payments - Street <span className="required">*</span>
                                                    </Typography>
                                                    <TextField
                                                        name="remit_Address_Street"
                                                        value={getCompanyDetails?.remit_Address_Street}
                                                        placeholder="Remit Address for Payments - Street"
                                                        className={classes.textfieldStyle}
                                                        InputProps={{
                                                            readOnly: true,
                                                            classes: {
                                                                root: classes.blueBorderBottom,
                                                                notchedOutline: classes.noBorder
                                                            },
                                                        }}
                                                        margin="dense" variant="outlined"
                                                    />
                                                </Grid>
                                                <Grid xs={12} sm={12} md={6} lg={4}>
                                                    <Typography variant="body1" className={classes.textfieldColor}>
                                                        Remit Address for Payments - City <span className="required">*</span>
                                                    </Typography>
                                                    <TextField
                                                        name="remit_Address_City"
                                                        value={getCompanyDetails?.remit_Address_City}
                                                        placeholder="Remit Address for Payments - City"
                                                        className={classes.textfieldStyle}
                                                        InputProps={{
                                                            readOnly: true,
                                                            classes: {
                                                                root: classes.blueBorderBottom,
                                                                notchedOutline: classes.noBorder
                                                            },
                                                        }}
                                                        margin="dense" variant="outlined"
                                                    />
                                                </Grid>
                                                <Grid xs={12} sm={12} md={12} lg={4}>
                                                    <Typography variant="body1" className={classes.textfieldColor}>
                                                        Remit Address for Payments - State <span className="required">*</span>
                                                    </Typography>
                                                    <TextField
                                                        name="remit_Address_State"
                                                        value={getCompanyDetails?.remit_Address_State}
                                                        placeholder="Remit Address for Payments - State"
                                                        className={classes.textfieldStyle}
                                                        InputProps={{
                                                            readOnly: true,
                                                            classes: {
                                                                root: classes.blueBorderBottom,
                                                                notchedOutline: classes.noBorder
                                                            },
                                                        }}
                                                        margin="dense" variant="outlined"
                                                    />
                                                </Grid>
                                                <Grid xs={12} sm={12} md={12} lg={4}>
                                                    <Typography variant="body1" className={classes.textfieldColor}>
                                                        Remit Address for Payments - Zip Code <span className="required">*</span>
                                                    </Typography>
                                                    <TextField
                                                        name="remit_Address_Zip"
                                                        value={getCompanyDetails?.remit_Address_Zip}
                                                        placeholder="Remit Address for Payments - Zip Code"
                                                        className={classes.textfieldStyle}
                                                        InputProps={{
                                                            readOnly: true,
                                                            classes: {
                                                                root: classes.blueBorderBottom,
                                                                notchedOutline: classes.noBorder
                                                            },
                                                        }}
                                                        margin="dense" variant="outlined"
                                                    />
                                                </Grid>

                                                <Grid xs={12} sm={12} md={12} lg={4}>
                                                    <Grid container spacing={0}>
                                                        <Grid item xs={8}>
                                                            <Typography variant="body1" className={classes.textfieldColor}>
                                                                Payment Website for Collection Notices
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={4}>
                                                            {!multipleBranchList?.payment_Website ? (
                                                                <Tooltip title="The URL is empty." style={{ marginBottom: '-10px' }}>
                                                                    <IconButton>
                                                                        <ErrorIcon />
                                                                    </IconButton>
                                                                </Tooltip>
                                                            ) : !paymenturlExist ? (
                                                                <Tooltip title="The domain does not exist." style={{ marginBottom: '0px' }}>
                                                                    <IconButton>
                                                                        <ErrorIcon />
                                                                    </IconButton>
                                                                </Tooltip>
                                                            ) : !paymenturlSecure ? (
                                                                <Tooltip title="The domain exists but the URL is not secured." style={{ marginBottom: '0px' }}>
                                                                    <IconButton>
                                                                        <ErrorIcon />
                                                                    </IconButton>
                                                                </Tooltip>
                                                            ) : (
                                                                <Tooltip title="The domain exists and the URL is secured." style={{ marginBottom: '0px' }}>
                                                                    <IconButton>
                                                                        <ErrorIcon />
                                                                    </IconButton>
                                                                </Tooltip>
                                                            )}
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <TextField
                                                            name="collection_Notice_Payment_website"
                                                            value={getCompanyDetails?.collection_Notice_Payment_website}
                                                            placeholder="Payment Website for Collection Notices"
                                                            className={classes.textfieldStyle}
                                                            InputProps={{
                                                                readOnly: true,
                                                                classes: {
                                                                    root: classes.blueBorderBottom,
                                                                    notchedOutline: classes.noBorder
                                                                },
                                                            }}
                                                            margin="dense" variant="outlined"
                                                        />
                                                    </Grid>
                                                </Grid>

                                                <Grid xs={12} sm={12} md={12} lg={6}>
                                                    <Typography variant="body1" className={classes.textfieldColor}>
                                                        Credit Cards Accepted <span className="required">*</span>
                                                    </Typography>
                                                    <FormControl component="fieldset">
                                                        <RadioGroup aria-label="gender" name="creditcard_Accepted" className={classes.radioGroup}>
                                                            {CreditDetails?.map((items) => {
                                                                return (
                                                                    <>
                                                                        <FormControlLabel control={<Checkbox name="creditcard_Accepted"
                                                                            checked={getCompanyDetails?.creditcard_Accepted?.includes(items?.title)}
                                                                            value={`${items?.title}`} />} label={`${items?.title}`} />
                                                                    </>
                                                                )
                                                            })}
                                                        </RadioGroup>
                                                    </FormControl>
                                                </Grid>

                                                <Grid xs={12} sm={12} md={12} lg={6}>
                                                    <Typography variant="body1" className={classes.textfieldColor}>
                                                        Are your accounts mostly? <span className="required">*</span>
                                                    </Typography>
                                                    <FormControl component="fieldset">
                                                        <RadioGroup aria-label="gender" name="account_Mostly" className={classes.radioGroup}>
                                                            <FormControlLabel value="responsible" control={<Radio />} label="Residential" checked={getCompanyDetails?.account_Mostly === "responsible"} />
                                                            <FormControlLabel value="commercial" control={<Radio />} label="Commercial" checked={getCompanyDetails?.account_Mostly === "commercial"} />
                                                            <FormControlLabel value="mixofboth" control={<Radio />} label="Even Mix of Both" checked={getCompanyDetails?.account_Mostly === "mixofboth"} />
                                                        </RadioGroup>
                                                    </FormControl>
                                                </Grid>
                                            </Grid>
                                            <Grid container component={Paper} spacing={0} className={classes.paddingStyle}>
                                                <Grid xs={12} sm={12} md={12} lg={12} >
                                                    <Typography variant='h6' className={classes.headStyle}>
                                                        <b>BILLING INFORMATION - OUR INVOICE TO YOU</b>
                                                    </Typography>
                                                </Grid>
                                                <Grid xs={12} sm={12} md={6} lg={4}>
                                                    <Typography variant="body1" className={classes.textfieldColor}>
                                                        Billing should be directed to: <span className="required">*</span>
                                                    </Typography>
                                                    <FormControl component="fieldset">
                                                        <RadioGroup aria-label="gender" name="billing_Directed_To" className={classes.radioGroup}>
                                                            <FormControlLabel value="Corporate" name="billing_Directed_To" control={<Radio />} label="Corporate" checked={getCompanyDetails?.billing_Directed_To === "Corporate"} />
                                                            <FormControlLabel value="The Branch" name="billing_Directed_To" control={<Radio />} label="The Branch" checked={getCompanyDetails?.billing_Directed_To === "The Branch"} />
                                                        </RadioGroup>
                                                    </FormControl>
                                                </Grid>
                                                <Grid xs={12} sm={12} md={6} lg={4}>
                                                    <Typography variant="body1" className={classes.textfieldColor}>
                                                        If you chose Corporate, who is our Billing Contact?
                                                    </Typography>
                                                    <TextField
                                                        name="billing_Contact"
                                                        value={getCompanyDetails?.billing_Contact}
                                                        placeholder="If you chose Corporate, who is our Billing Contact?"
                                                        className={classes.textfieldStyle}
                                                        InputProps={{
                                                            readOnly: true,
                                                            classes: {
                                                                root: classes.blueBorderBottom,
                                                                notchedOutline: classes.noBorder
                                                            },
                                                        }}
                                                        margin="dense" variant="outlined"
                                                    />
                                                </Grid>
                                                <Grid xs={12} sm={12} md={6} lg={4}>
                                                    <Typography variant="body1" className={classes.textfieldColor}>
                                                        Billing Contact Title
                                                    </Typography>
                                                    <TextField
                                                        name="billing_Contact_Title"
                                                        value={getCompanyDetails?.billing_Contact_Title}
                                                        placeholder="Billing Contact Title"
                                                        className={classes.textfieldStyle}
                                                        InputProps={{
                                                            readOnly: true,
                                                            classes: {
                                                                root: classes.blueBorderBottom,
                                                                notchedOutline: classes.noBorder
                                                            },
                                                        }}
                                                        margin="dense" variant="outlined"
                                                    />
                                                </Grid>
                                                <Grid xs={12} sm={12} md={6} lg={4}>
                                                    <Typography variant="body1" className={classes.textfieldColor}>
                                                        Billing Contact Phone Number
                                                    </Typography>
                                                    <TextField
                                                        name="billing_Contact_Phone"
                                                        value={getCompanyDetails?.billing_Contact_Phone}
                                                        placeholder="Billing Contact Phone Number"
                                                        className={classes.phoneNumberField}
                                                        InputProps={{
                                                            readOnly: true,
                                                            classes: {
                                                                root: classes.blueBorderBottom,
                                                                notchedOutline: classes.noBorder
                                                            },
                                                        }}
                                                        margin="dense" variant="outlined"
                                                    />
                                                    <TextField
                                                        name="billing_Contact_PhoneExt"
                                                        placeholder="Ext."
                                                        value={getCompanyDetails?.billing_Contact_PhoneExt}
                                                        className={classes.phoneNumberExt}
                                                        InputProps={{
                                                            readOnly: true,
                                                            classes: {
                                                                root: classes.blueBorderBottom,
                                                                notchedOutline: classes.noBorder
                                                            },
                                                            inputProps: {
                                                                maxLength: 10 // Replace 10 with your desired maximum length
                                                            }
                                                        }}
                                                        margin="dense" variant="outlined"
                                                    />
                                                </Grid>
                                                <Grid xs={12} sm={12} md={12} lg={4}>
                                                    <Typography variant="body1" className={classes.textfieldColor}>
                                                        Billing Contact Email
                                                    </Typography>
                                                    <TextField
                                                        name="billing_Contact_Email"
                                                        value={getCompanyDetails?.billing_Contact_Email}
                                                        placeholder="Billing Contact Email"
                                                        className={classes.textfieldStyle}
                                                        InputProps={{
                                                            readOnly: true,
                                                            classes: {
                                                                root: classes.blueBorderBottom,
                                                                notchedOutline: classes.noBorder
                                                            },
                                                        }}
                                                        margin="dense" variant="outlined"
                                                    />
                                                </Grid>
                                                <Grid xs={12} sm={12} md={12} lg={4}>
                                                    <Typography variant="body1" className={classes.textfieldColor}>
                                                        Billing Address - Street
                                                    </Typography>
                                                    <TextField
                                                        name="billing_Address_Street"
                                                        value={getCompanyDetails?.billing_Address_Street}
                                                        placeholder="Billing Address - Street"
                                                        className={classes.textfieldStyle}
                                                        InputProps={{
                                                            readOnly: true,
                                                            classes: {
                                                                root: classes.blueBorderBottom,
                                                                notchedOutline: classes.noBorder
                                                            },
                                                        }}
                                                        margin="dense" variant="outlined"
                                                    />
                                                </Grid>
                                                <Grid xs={12} sm={12} md={12} lg={4}>
                                                    <Typography variant="body1" className={classes.textfieldColor}>
                                                        Billing Address - City
                                                    </Typography>
                                                    <TextField
                                                        name="billing_Address_City"
                                                        value={getCompanyDetails?.billing_Address_City}
                                                        placeholder="Billing Address - City"
                                                        className={classes.textfieldStyle}
                                                        InputProps={{
                                                            readOnly: true,
                                                            classes: {
                                                                root: classes.blueBorderBottom,
                                                                notchedOutline: classes.noBorder
                                                            },
                                                        }}
                                                        margin="dense" variant="outlined"
                                                    />
                                                </Grid>
                                                <Grid xs={12} sm={12} md={12} lg={4}>
                                                    <Typography variant="body1" className={classes.textfieldColor}>
                                                        Billing Address - State
                                                    </Typography>
                                                    <TextField
                                                        name="billing_Address_State"
                                                        value={getCompanyDetails?.billing_Address_State}
                                                        placeholder="Billing Address - State"
                                                        className={classes.textfieldStyle}
                                                        InputProps={{
                                                            readOnly: true,
                                                            classes: {
                                                                root: classes.blueBorderBottom,
                                                                notchedOutline: classes.noBorder
                                                            },
                                                        }}
                                                        margin="dense" variant="outlined"
                                                    />
                                                </Grid>
                                                <Grid xs={12} sm={12} md={12} lg={4}>
                                                    <Typography variant="body1" className={classes.textfieldColor}>
                                                        Billing Address - Zip Code
                                                    </Typography>
                                                    <TextField
                                                        name="billing_Address_Zip"
                                                        value={getCompanyDetails?.billing_Address_Zip}
                                                        placeholder="Billing Address - Zip Code"
                                                        className={classes.textfieldStyle}
                                                        InputProps={{
                                                            readOnly: true,
                                                            classes: {
                                                                root: classes.blueBorderBottom,
                                                                notchedOutline: classes.noBorder
                                                            },
                                                        }}
                                                        margin="dense" variant="outlined"
                                                    />
                                                </Grid>
                                            </Grid>
                                            <Grid container component={Paper} spacing={0} className={classes.paddingStyle}>
                                                <Grid xs={12} sm={12} md={12} lg={12}>
                                                    <Typography variant='h6' className={classes.headStyle}>
                                                        <b>REPORTING</b>
                                                    </Typography>
                                                </Grid>
                                                <Grid xs={12} sm={12} md={6} lg={6}>
                                                    <FormControlLabel
                                                        className={classes.textfieldColor}
                                                        control={<Checkbox checked={getCompanyDetails?.monthlyPerformanceReport} disabled={true}
                                                            inputProps={{ 'aria-label': 'controlled' }}
                                                            name="monthlyPerformanceReport" />
                                                        }
                                                        labelPlacement="end"
                                                        label={
                                                            <Typography style={{ fontWeight: 'bold' }}>
                                                                Monthly Performance Report <span className="required">*</span>
                                                            </Typography>
                                                        }
                                                    />
                                                    <TextField
                                                        id="outlined-basic"
                                                        size="small"
                                                        label="Email Address"
                                                        name="dayTodayAccountReportEmail"
                                                        value={getCompanyDetails?.dayTodayAccountReportEmail}
                                                        className={classes.textfieldStyle}
                                                        InputProps={{
                                                            readOnly: true,
                                                            classes: {
                                                                root: classes.blueBorderBottom,
                                                                notchedOutline: classes.noBorder
                                                            },
                                                        }}
                                                        variant="outlined"
                                                    />
                                                </Grid>
                                                <Grid xs={12} sm={12} md={6} lg={5}>
                                                    <FormControlLabel
                                                        className={classes.textfieldColor}
                                                        control={<Checkbox checked={getCompanyDetails?.dayTodayAccountReports} disabled={true}
                                                            inputProps={{ 'aria-label': 'controlled' }}
                                                            name="dayTodayAccountReports" />
                                                        }
                                                        labelPlacement="end"
                                                        label={
                                                            <Typography style={{ fontWeight: 'bold' }}>
                                                                Day to Day Account Update Reports <span className="required">*</span>
                                                            </Typography>
                                                        }
                                                    />
                                                    <TextField
                                                        id="outlined-basic"
                                                        size="small"
                                                        label="Email Address"
                                                        name="reportEmailAddress"
                                                        value={getCompanyDetails?.reportEmailAddress}
                                                        className={classes.textfieldStyle}
                                                        InputProps={{
                                                            readOnly: true,
                                                            classes: {
                                                                root: classes.blueBorderBottom,
                                                                notchedOutline: classes.noBorder
                                                            },
                                                        }}
                                                        variant="outlined"
                                                    />
                                                </Grid>
                                            </Grid>
                                        </TableContainer>
                                    </div >
                                    :
                                    multiLocation != 0 ?
                                        branchNameList?.map((row, index) => (
                                            <div >
                                                < TableContainer key={row?.branchId} className={`${classes.pageLayout} ${"scrollbox"} ${"on-scrollbar"}`}>
                                                    <Grid container component={Paper} spacing={0} style={{ padding: '10px' }}>
                                                        <Grid xs={12} sm={12} md={12} lg={12} >
                                                            <Typography variant='h6' className={classes.headStyle}>
                                                                <b>BRANCH / LOCATION INFORMATION</b>
                                                            </Typography>
                                                        </Grid>
                                                        <Grid xs={12} sm={12} md={6} lg={4}>
                                                            <Typography variant="body1" className={classes.textfieldColor}>
                                                                Branch/Location Name and/or Number
                                                            </Typography>
                                                            <TextField
                                                                autoComplete='off'
                                                                name="branch_Location_Name"
                                                                placeholder="Location Name"
                                                                value={multipleBranchList?.branch_Location_Name}
                                                                className={classes.textfieldStyle}
                                                                InputProps={{
                                                                    readOnly: true,
                                                                    classes: {
                                                                        root: classes.blueBorderBottom,
                                                                        notchedOutline: classes.noBorder
                                                                    },
                                                                }}
                                                                margin="dense" variant="outlined"
                                                            />
                                                        </Grid>
                                                        <Grid xs={12} sm={12} md={6} lg={4}>
                                                            <Typography variant="body1" className={classes.textfieldColor}>
                                                                Day to Day Contact <span className="required">*</span>
                                                            </Typography>
                                                            <TextField
                                                                autoComplete='off'
                                                                name="day_Today_Contact"
                                                                placeholder="Contact"
                                                                value={multipleBranchList?.day_Today_Contact}
                                                                className={classes.textfieldStyle}
                                                                InputProps={{
                                                                    readOnly: true,
                                                                    classes: {
                                                                        root: classes.blueBorderBottom,
                                                                        notchedOutline: classes.noBorder
                                                                    },
                                                                }}
                                                                margin="dense" variant="outlined"
                                                            />
                                                        </Grid>
                                                        <Grid xs={12} sm={12} md={6} lg={4}>
                                                            <Typography variant="body1" className={classes.textfieldColor}>
                                                                Day to Day Contact Title <span className="required">*</span>
                                                            </Typography>
                                                            <TextField
                                                                autoComplete='off'
                                                                name="day_Today_Contact_Title"
                                                                value={multipleBranchList?.day_Today_Contact_Title}
                                                                className={classes.textfieldStyle}
                                                                InputProps={{
                                                                    readOnly: true,
                                                                    classes: {
                                                                        root: classes.blueBorderBottom,
                                                                        notchedOutline: classes.noBorder
                                                                    },
                                                                }}
                                                                margin="dense" variant="outlined"
                                                            />
                                                        </Grid>
                                                        <Grid xs={12} sm={12} md={6} lg={6}>
                                                            <Typography variant="body1" className={classes.textfieldColor}>
                                                                Day to Day Contact Phone Number <span className="required">*</span>
                                                            </Typography>
                                                            <TextField
                                                                autoComplete='off'
                                                                name="day_Today_Contact_Phone"
                                                                value={multipleBranchList?.day_Today_Contact_Phone}
                                                                className={classes.phoneNumberField}
                                                                InputProps={{
                                                                    readOnly: true,
                                                                    classes: {
                                                                        root: classes.blueBorderBottom,
                                                                        notchedOutline: classes.noBorder
                                                                    },
                                                                }}
                                                                margin="dense" variant="outlined"
                                                            />
                                                            <TextField
                                                                name="day_Today_Contact_PhoneExt"
                                                                placeholder="Ext."
                                                                value={multipleBranchList?.day_Today_Contact_PhoneExt}
                                                                className={classes.phoneNumberExt}
                                                                InputProps={{
                                                                    readOnly: true,
                                                                    classes: {
                                                                        root: classes.blueBorderBottom,
                                                                        notchedOutline: classes.noBorder
                                                                    },
                                                                }}
                                                                margin="dense" variant="outlined"
                                                            />
                                                        </Grid>
                                                        <Grid xs={12} sm={12} md={12} lg={6}>
                                                            <Typography variant="body1" className={classes.textfieldColor}>
                                                                Day to Day Contact Email <span className="required">*</span>
                                                            </Typography>
                                                            <TextField
                                                                autoComplete='off'
                                                                name="day_Today_Contact_Email"
                                                                value={multipleBranchList?.day_Today_Contact_Email}
                                                                className={classes.textfieldStyle}
                                                                InputProps={{
                                                                    readOnly: true,
                                                                    classes: {
                                                                        root: classes.blueBorderBottom,
                                                                        notchedOutline: classes.noBorder
                                                                    },
                                                                }}
                                                                margin="dense" variant="outlined"
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                    <Grid container component={Paper} spacing={0} className={classes.paddingStyle}>
                                                        <Grid xs={12} sm={12} md={12} lg={12} >
                                                            <Typography variant='h6' className={classes.headStyle}>
                                                                <b>COLLECTION SETUP INFORMATION</b>
                                                            </Typography>
                                                        </Grid>
                                                        <Grid xs={12} sm={12} md={6} lg={4}>
                                                            <Typography variant="body1" className={classes.textfieldColor}>
                                                                Company Name to Display on Collection Notices <span className="required">*</span>
                                                            </Typography>
                                                            <TextField
                                                                name="comp_Name"
                                                                value={multipleBranchList?.comp_Name}
                                                                placeholder="Company Name to Display on Collection Notices"
                                                                className={classes.textfieldStyle}
                                                                InputProps={{
                                                                    readOnly: true,
                                                                    classes: {
                                                                        root: classes.blueBorderBottom,
                                                                        notchedOutline: classes.noBorder
                                                                    },
                                                                }}
                                                                margin="dense" variant="outlined"
                                                            />
                                                        </Grid>
                                                        <Grid xs={12} sm={12} md={6} lg={4}>
                                                            <Typography variant="body1" className={classes.textfieldColor}>
                                                                Phone Number for Collection Notices <span className="required">*</span>
                                                            </Typography>
                                                            <TextField
                                                                name="collection_Notice_Phone"
                                                                value={multipleBranchList?.collection_Notice_Phone}
                                                                placeholder="Phone Number for Collection Notices"
                                                                className={classes.phoneNumberField}
                                                                InputProps={{
                                                                    readOnly: true,
                                                                    classes: {
                                                                        root: classes.blueBorderBottom,
                                                                        notchedOutline: classes.noBorder
                                                                    },
                                                                }}
                                                                margin="dense" variant="outlined"
                                                            />
                                                            <TextField
                                                                name="collection_Notice_PhoneExt"
                                                                placeholder="Ext."
                                                                value={multipleBranchList?.collection_Notice_PhoneExt}
                                                                className={classes.phoneNumberExt}
                                                                InputProps={{
                                                                    readOnly: true,
                                                                    classes: {
                                                                        root: classes.blueBorderBottom,
                                                                        notchedOutline: classes.noBorder
                                                                    },
                                                                    inputProps: {
                                                                        maxLength: 10 // Replace 10 with your desired maximum length
                                                                    }
                                                                }}
                                                                margin="dense" variant="outlined"
                                                            />
                                                        </Grid>
                                                        <Grid xs={12} sm={12} md={6} lg={4}>
                                                            <Typography variant="body1" className={classes.textfieldColor}>
                                                                Remit Address for Payments - Street <span className="required">*</span>
                                                            </Typography>
                                                            <TextField
                                                                name="remit_Address_Street"
                                                                value={multipleBranchList?.remit_Address_Street}
                                                                placeholder="Remit Address for Payments - Street"
                                                                className={classes.textfieldStyle}
                                                                InputProps={{
                                                                    readOnly: true,
                                                                    classes: {
                                                                        root: classes.blueBorderBottom,
                                                                        notchedOutline: classes.noBorder
                                                                    },
                                                                }}
                                                                margin="dense" variant="outlined"
                                                            />
                                                        </Grid>
                                                        <Grid xs={12} sm={12} md={6} lg={4}>
                                                            <Typography variant="body1" className={classes.textfieldColor}>
                                                                Remit Address for Payments - City <span className="required">*</span>
                                                            </Typography>
                                                            <TextField
                                                                name="remit_Address_City"
                                                                value={multipleBranchList?.remit_Address_City}
                                                                placeholder="Remit Address for Payments - City"
                                                                className={classes.textfieldStyle}
                                                                InputProps={{
                                                                    readOnly: true,
                                                                    classes: {
                                                                        root: classes.blueBorderBottom,
                                                                        notchedOutline: classes.noBorder
                                                                    },
                                                                }}
                                                                margin="dense" variant="outlined"
                                                            />
                                                        </Grid>
                                                        <Grid xs={12} sm={12} md={12} lg={4}>
                                                            <Typography variant="body1" className={classes.textfieldColor}>
                                                                Remit Address for Payments - State <span className="required">*</span>
                                                            </Typography>
                                                            <TextField
                                                                name="remit_Address_State"
                                                                value={multipleBranchList?.remit_Address_State}
                                                                placeholder="Remit Address for Payments - State"
                                                                className={classes.textfieldStyle}
                                                                InputProps={{
                                                                    readOnly: true,
                                                                    classes: {
                                                                        root: classes.blueBorderBottom,
                                                                        notchedOutline: classes.noBorder
                                                                    },
                                                                }}
                                                                margin="dense" variant="outlined"
                                                            />
                                                        </Grid>
                                                        <Grid xs={12} sm={12} md={12} lg={4}>
                                                            <Typography variant="body1" className={classes.textfieldColor}>
                                                                Remit Address for Payments - Zip Code <span className="required">*</span>
                                                            </Typography>
                                                            <TextField
                                                                name="remit_Address_Zip"
                                                                value={multipleBranchList?.remit_Address_Zip}
                                                                placeholder="Remit Address for Payments - Zip Code"
                                                                className={classes.textfieldStyle}
                                                                InputProps={{
                                                                    readOnly: true,
                                                                    classes: {
                                                                        root: classes.blueBorderBottom,
                                                                        notchedOutline: classes.noBorder
                                                                    },
                                                                }}
                                                                margin="dense" variant="outlined"
                                                            />
                                                        </Grid>
                                                        <Grid xs={12} sm={12} md={12} lg={4}>
                                                            <Grid container spacing={0}>
                                                                <Grid item xs={8}>
                                                                    <Typography variant="body1" className={classes.textfieldColor}>
                                                                        Payment Website for Collection Notices
                                                                    </Typography>
                                                                </Grid>
                                                                <Grid item xs={4}>
                                                                    {!multipleBranchList?.payment_Website ? (
                                                                        <Tooltip title="The URL is empty." style={{ marginBottom: '0px' }}>
                                                                            <IconButton>
                                                                                <ErrorIcon fontSize='small' />
                                                                            </IconButton>
                                                                        </Tooltip>
                                                                    ) : !branchPaymenturlExist ? (
                                                                        <Tooltip title="This domain does not exist." style={{ marginBottom: '0px' }}>
                                                                            <IconButton>
                                                                                <ErrorIcon />
                                                                            </IconButton>
                                                                        </Tooltip>
                                                                    ) : !branchPaymentSecure ? (
                                                                        <Tooltip title="The domain exists but the URL is not secured." style={{ marginBottom: '0px' }}>
                                                                            <IconButton>
                                                                                <ErrorIcon />
                                                                            </IconButton>
                                                                        </Tooltip>
                                                                    ) : (
                                                                        <Tooltip title="The domain exists and the URL is secured." style={{ marginBottom: '0px' }}>
                                                                            <IconButton>
                                                                                <ErrorIcon />
                                                                            </IconButton>
                                                                        </Tooltip>
                                                                    )}
                                                                </Grid>
                                                                <Grid item xs={12}>
                                                                    <TextField
                                                                        name="payment_Website"
                                                                        value={multipleBranchList?.payment_Website}
                                                                        placeholder="Payment Website for Collection Notices"
                                                                        className={classes.textfieldStyle}
                                                                        InputProps={{
                                                                            readOnly: true,
                                                                            classes: {
                                                                                root: classes.blueBorderBottom,
                                                                                notchedOutline: classes.noBorder
                                                                            },
                                                                        }}
                                                                        margin="dense" variant="outlined"
                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                        <Grid xs={12} sm={12} md={12} lg={8}>
                                                            <Typography variant="body1" className={classes.textfieldColor}>
                                                                Credit Cards Accepted <span className="required">*</span>
                                                            </Typography>
                                                            <FormControl component="fieldset">
                                                                <RadioGroup aria-label="gender" name="creditcard_Accepted" className={classes.radioGroup}>
                                                                    {CreditDetails?.map((items) => {
                                                                        return (
                                                                            <>
                                                                                <FormControlLabel control={<Checkbox name="creditcard_Accepted"
                                                                                    checked={multipleBranchList?.credit_Card_Accepted?.includes(items?.title)}
                                                                                    value={`${items?.title}`} />} label={`${items?.title}`} />
                                                                            </>
                                                                        )
                                                                    })}
                                                                </RadioGroup>
                                                            </FormControl>
                                                        </Grid>
                                                        <Grid xs={12} sm={12} md={12} lg={12}>
                                                            <Typography variant="body1" className={classes.textfieldColor}>
                                                                Are your accounts mostly? <span className="required">*</span>
                                                            </Typography>
                                                            <FormControl component="fieldset">
                                                                <RadioGroup aria-label="gender" name="accounts_Mostly" className={classes.radioGroup}>
                                                                    <FormControlLabel value="responsible" control={<Radio checked={multipleBranchList?.accounts_Mostly === "responsible"} />} label="Residential" />
                                                                    <FormControlLabel value="commercial" control={<Radio checked={multipleBranchList?.accounts_Mostly === "commercial"} />} label="Commercial" />
                                                                    <FormControlLabel value="mixofboth" control={<Radio checked={multipleBranchList?.accounts_Mostly === "mixofboth"} />} label="Even Mix of Both" />
                                                                </RadioGroup>
                                                            </FormControl>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid container component={Paper} spacing={0} className={classes.paddingStyle}>
                                                        <Grid xs={12} sm={12} md={12} lg={12} >
                                                            <Typography variant='h6' className={classes.headStyle}>
                                                                <b>BILLING INFORMATION - OUR INVOICE TO YOU</b>
                                                            </Typography>
                                                        </Grid>
                                                        <Grid xs={12} sm={12} md={6} lg={4}>
                                                            <Typography variant="body1" className={classes.textfieldColor}>
                                                                Billing should be directed to: <span className="required">*</span>
                                                            </Typography>
                                                            <FormControl component="fieldset">
                                                                <RadioGroup aria-label="gender" name="billing_Contact" className={classes.radioGroup}>
                                                                    <FormControlLabel value="Corporate" control={<Radio checked={multipleBranchList?.billing_Contact === "Corporate"} />} label="Corporate" />
                                                                    <FormControlLabel value="Branch" control={<Radio checked={multipleBranchList?.billing_Contact === "Branch"} />} label="The Branch" defaultChecked />
                                                                </RadioGroup>
                                                            </FormControl>
                                                        </Grid>
                                                        <Grid xs={12} sm={12} md={6} lg={4}>
                                                            <Typography variant="body1" className={classes.textfieldColor}>
                                                                If you chose Corporate, who is our Billing Contact?
                                                            </Typography>
                                                            <TextField
                                                                name="billing_Contact_Person"
                                                                value={multipleBranchList?.billing_Contact_Person}
                                                                placeholder="If you chose Corporate, who is our Billing Contact?"
                                                                className={classes.textfieldStyle}
                                                                InputProps={{
                                                                    readOnly: true,
                                                                    classes: {
                                                                        root: classes.blueBorderBottom,
                                                                        notchedOutline: classes.noBorder
                                                                    },
                                                                }}
                                                                margin="dense" variant="outlined"
                                                            />
                                                        </Grid>
                                                        <Grid xs={12} sm={12} md={6} lg={4}>
                                                            <Typography variant="body1" className={classes.textfieldColor}>
                                                                Billing Contact Title
                                                            </Typography>
                                                            <TextField
                                                                name="billing_Contact_Title"
                                                                value={multipleBranchList?.billing_Contact_Title}
                                                                placeholder="Billing Contact Title"
                                                                className={classes.textfieldStyle}
                                                                InputProps={{
                                                                    readOnly: true,
                                                                    classes: {
                                                                        root: classes.blueBorderBottom,
                                                                        notchedOutline: classes.noBorder
                                                                    },
                                                                }}
                                                                margin="dense" variant="outlined"
                                                            />
                                                        </Grid>
                                                        <Grid xs={12} sm={12} md={6} lg={4}>
                                                            <Typography variant="body1" className={classes.textfieldColor}>
                                                                Billing Contact Phone Number
                                                            </Typography>
                                                            <TextField
                                                                name="billing_Contact_Phone"
                                                                value={multipleBranchList?.billing_Contact_Phone}
                                                                placeholder="Billing Contact Phone Number"
                                                                className={classes.phoneNumberField}
                                                                InputProps={{
                                                                    readOnly: true,
                                                                    classes: {
                                                                        root: classes.blueBorderBottom,
                                                                        notchedOutline: classes.noBorder
                                                                    },
                                                                }}
                                                                margin="dense" variant="outlined"
                                                            />
                                                            <TextField
                                                                name="billing_Contact_PhoneExt"
                                                                placeholder="Ext."
                                                                value={multipleBranchList?.billing_Contact_PhoneExt}
                                                                className={classes.phoneNumberExt}
                                                                InputProps={{
                                                                    readOnly: true,
                                                                    classes: {
                                                                        root: classes.blueBorderBottom,
                                                                        notchedOutline: classes.noBorder
                                                                    },
                                                                }}
                                                                margin="dense" variant="outlined"
                                                            />
                                                        </Grid>
                                                        <Grid xs={12} sm={12} md={12} lg={4}>
                                                            <Typography variant="body1" className={classes.textfieldColor}>
                                                                Billing Contact Email
                                                            </Typography>
                                                            <TextField
                                                                name="billing_Contact_Email"
                                                                value={multipleBranchList?.billing_Contact_Email}
                                                                placeholder="Billing Contact Email"
                                                                className={classes.textfieldStyle}
                                                                InputProps={{
                                                                    readOnly: true,
                                                                    classes: {
                                                                        root: classes.blueBorderBottom,
                                                                        notchedOutline: classes.noBorder
                                                                    },
                                                                }}
                                                                margin="dense" variant="outlined"
                                                            />
                                                        </Grid>
                                                        <Grid xs={12} sm={12} md={12} lg={4}>
                                                            <Typography variant="body1" className={classes.textfieldColor}>
                                                                Billing Address - Street
                                                            </Typography>
                                                            <TextField
                                                                name="billing_Address_Street"
                                                                value={multipleBranchList?.billing_Address_Street}
                                                                placeholder="Billing Address - Street"
                                                                className={classes.textfieldStyle}
                                                                InputProps={{
                                                                    readOnly: true,
                                                                    classes: {
                                                                        root: classes.blueBorderBottom,
                                                                        notchedOutline: classes.noBorder
                                                                    },
                                                                }}
                                                                margin="dense" variant="outlined"
                                                            />
                                                        </Grid>
                                                        <Grid xs={12} sm={12} md={12} lg={4}>
                                                            <Typography variant="body1" className={classes.textfieldColor}>
                                                                Billing Address - City
                                                            </Typography>
                                                            <TextField
                                                                name="billing_Address_City"
                                                                value={multipleBranchList?.billing_Address_City}
                                                                placeholder="Billing Address - City"
                                                                className={classes.textfieldStyle}
                                                                InputProps={{
                                                                    readOnly: true,
                                                                    classes: {
                                                                        root: classes.blueBorderBottom,
                                                                        notchedOutline: classes.noBorder
                                                                    },
                                                                }}
                                                                margin="dense" variant="outlined"
                                                            />
                                                        </Grid>
                                                        <Grid xs={12} sm={12} md={12} lg={4}>
                                                            <Typography variant="body1" className={classes.textfieldColor}>
                                                                Billing Address - State
                                                            </Typography>
                                                            <TextField
                                                                name="billing_Address_State"
                                                                value={multipleBranchList?.billing_Address_State}
                                                                placeholder="Billing Address - State"
                                                                className={classes.textfieldStyle}
                                                                InputProps={{
                                                                    readOnly: true,
                                                                    classes: {
                                                                        root: classes.blueBorderBottom,
                                                                        notchedOutline: classes.noBorder
                                                                    },
                                                                }}
                                                                margin="dense" variant="outlined"
                                                            />
                                                        </Grid>
                                                        <Grid xs={12} sm={12} md={12} lg={4}>
                                                            <Typography variant="body1" className={classes.textfieldColor}>
                                                                Billing Address - Zip Code
                                                            </Typography>
                                                            <TextField
                                                                name="billing_Address_Zip"
                                                                value={multipleBranchList?.billing_Address_Zip}
                                                                placeholder="Billing Address - Zip Code"
                                                                className={classes.textfieldStyle}
                                                                InputProps={{
                                                                    readOnly: true,
                                                                    classes: {
                                                                        root: classes.blueBorderBottom,
                                                                        notchedOutline: classes.noBorder
                                                                    },
                                                                }}
                                                                margin="dense" variant="outlined"
                                                            />
                                                        </Grid>
                                                        <Grid container component={Paper} spacing={0} className={classes.paddingStyle}>
                                                            <Grid xs={12} sm={12} md={12} lg={12}>
                                                                <Typography variant='h6' className={classes.headStyle}>
                                                                    <b>REPORTING</b>
                                                                </Typography>
                                                            </Grid>
                                                            <Grid xs={12} sm={12} md={6} lg={6}>
                                                                <FormControlLabel
                                                                    className={classes.textfieldColor}
                                                                    control={<Checkbox checked={multipleBranchList?.monthlyPerformanceReport} disabled={true}
                                                                        inputProps={{ 'aria-label': 'controlled' }}
                                                                        name="monthlyPerformanceReport" />
                                                                    }
                                                                    labelPlacement="end"
                                                                    label={
                                                                        <Typography style={{ fontWeight: 'bold' }}>
                                                                            Monthly Performance Report <span className="required">*</span>
                                                                        </Typography>
                                                                    }
                                                                />
                                                                <TextField
                                                                    id="outlined-basic"
                                                                    size="small"
                                                                    label="Email Address"
                                                                    name="dayTodayAccountReportEmail"
                                                                    value={multipleBranchList?.dayTodayAccountReportEmail}
                                                                    className={classes.textfieldStyle}
                                                                    InputProps={{
                                                                        readOnly: true,
                                                                        classes: {
                                                                            root: classes.blueBorderBottom,
                                                                            notchedOutline: classes.noBorder
                                                                        },
                                                                    }}
                                                                    variant="outlined"
                                                                />
                                                            </Grid>
                                                            <Grid xs={12} sm={12} md={6} lg={6}>
                                                                <FormControlLabel
                                                                    className={classes.textfieldColor}
                                                                    control={<Checkbox checked={multipleBranchList?.dayTodayAccountReports} disabled={true}
                                                                        inputProps={{ 'aria-label': 'controlled' }}
                                                                        name="dayTodayAccountReports" />
                                                                    }
                                                                    labelPlacement="end"
                                                                    label={
                                                                        <Typography style={{ fontWeight: 'bold' }}>
                                                                            Day to Day Account Update Reports <span className="required">*</span>
                                                                        </Typography>
                                                                    }
                                                                />
                                                                <TextField
                                                                    id="outlined-basic"
                                                                    size="small"
                                                                    label="Email Address"
                                                                    name="reportEmailAddress"
                                                                    value={multipleBranchList?.reportEmailAddress}
                                                                    className={classes.textfieldStyle}
                                                                    InputProps={{
                                                                        readOnly: true,
                                                                        classes: {
                                                                            root: classes.blueBorderBottom,
                                                                            notchedOutline: classes.noBorder
                                                                        },
                                                                    }}
                                                                    variant="outlined"
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </TableContainer>
                                            </div>
                                        ))
                                        : null}
                            </Grid>
                        </Grid>
                        <Footer />
                    </div>
                </div>

                <Dialog TransitionComponent={Transition}
                    open={initiateOpen} onClose={handlesetInitiateClose} maxWidth={'sm'}
                    aria-labelledby="responsive-dialog-title" PaperProps={{ style: { borderRadius: 10 } }} >
                    <DialogTitleDelete id="responsive-dialog-title" onClose={handlesetInitiateClose}>
                        <Typography variant="h6" className={classes.titleheader}>
                            SEND CORRECTIONS TO ({userRow?.prospectGivenName} {userRow?.prospectLastName} )
                        </Typography>
                    </DialogTitleDelete>
                    <DialogContent>
                        <Grid container >
                            <Grid item xs={12}>
                                <Typography variant="subtitle1" gutterBottom style={{ fontSize: '18px' }}>
                                    <b>Are you sure, you want to send corrections to {userRow?.prospectGivenName} {userRow?.prospectLastName}?</b>
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="body2" className={`${classes.textfield} ${classes.onboardClient}`} gutterBottom >
                                    <span><b>Note : </b></span> The email addressee will recieve this communication.
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField id="Email" label="Email" value={EmailName}
                                    autoComplete="off"
                                    size="small"
                                    onChange={(e) => { handleOnBlur(e); setEmailName(e.target.value); }}
                                    onKeyPress={preventSpecialCharacters}
                                    placeholder="example@gmail.com , example2@gmail.com" fullWidth
                                    variant="outlined" style={{ marginTop: "10px" }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }} required helperText={Emailerror} error={!!Emailerror}
                                    inputProps={{ maxlength: 100 }}
                                />

                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    id="outlined-multiline-static"
                                    label="Enter the Corrections"
                                    multiline fullWidth rows={6}
                                    style={{ marginTop: '15px' }}
                                    variant="outlined"
                                    onChange={(e) => { setDescription(e.target.value); }}
                                />
                            </Grid>
                            <Grid item xs={4} style={{ padding: '10px' }}>
                            </Grid>
                            <Grid item xs={8} style={{ padding: '10px' }}>
                                <Button id="DU_ok_btn" size="small" variant="contained" startIcon={<DoneAllIcon />} disabled={((!EmailName || !Description) || isDisableEmail)} onClick={(e) => { handleReinitiateOk(); }} className={classes.yesButton}>
                                    CONFIRM / SEND
                                </Button>
                                <Button id="DU_Cancel_btn" size="small" variant="contained" startIcon={<CancelIcon />} autoFocus onClick={handlesetInitiateClose} color="primary" className={classes.noButton}>
                                    Close
                                </Button>
                            </Grid>
                        </Grid>
                    </DialogContent>
                    {/* <DialogActions>
                    </DialogActions> */}
                </Dialog>

                <Dialog TransitionComponent={Transition}
                    open={clientInfoOpen} onClose={handlesetClientInfoClose} maxWidth={'sm'}
                    aria-labelledby="responsive-dialog-title" PaperProps={{ style: { borderRadius: 10 } }} >
                    <DialogTitleHeader id="responsive-dialog-title" onClose={handlesetClientInfoClose}>
                        <Typography variant="h6" className={classes.titleheader}>
                            APPROVE CLIENT DATA TO ({userRow?.prospectGivenName} {userRow?.prospectLastName} )
                        </Typography>
                    </DialogTitleHeader>
                    <DialogContent>
                        <Typography variant="h6" gutterBottom style={{ fontSize: '18px' }}>
                            Are you sure, you have verified the client information?
                        </Typography>

                    </DialogContent>
                    <DialogActions>
                        <Button id="DU_ok_btn" size="small" variant="contained" startIcon={<DoneAllIcon />} onClick={() => { handleClickApproveClientData(); }} className={classes.yesButton}>
                            Yes
                        </Button>
                        <Button id="DU_Cancel_btn" size="small" variant="contained" startIcon={<CancelIcon />} autoFocus onClick={handlesetClientInfoClose} color="primary" className={classes.noButton}>
                            NO
                        </Button>
                    </DialogActions>
                </Dialog>
            </Dialog >

            <Snackbar className="snackBarStyle" open={isCorrectionUpadate} anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }} autoHideDuration={4000} onClose={closeSnackbar}>
                <Alert onClose={closeSnackbar} severity="success" className="alertStyle">
                    Correction Sent Successfully!
                </Alert>
            </Snackbar>

            <Snackbar className="snackBarStyle" open={isApproveData} anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }} autoHideDuration={4000} onClose={closeSnackbar}>
                <Alert onClose={closeSnackbar} severity="success" className="alertStyle">
                    Client Data Approved Successfully!
                </Alert>
            </Snackbar>
        </div >
    );
}

export default MultiLocationDetails