import {
  IconButton, Typography, Button, Dialog, DialogActions, Snackbar, createStyles,
  makeStyles, Theme, useTheme, useMediaQuery, DialogContent
} from '@material-ui/core';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import Backdrop from '@mui/material/Backdrop/Backdrop';
import React, { useEffect, useState } from 'react';
import PuffLoader from "react-spinners/PuffLoader";
import { IReportsList } from '../../models/Reports/ReportsList';
import { GlobalStateAction, useGlobalState } from '../../store/GlobalStore';
import { useDelete } from '../../utils/apiHelper';
import { DialogTitleDelete } from '../GlobalStyles/DialogStyle';
import { Transition } from '../GlobalStyles/DialogBoxTransition';

const DeleteReport: React.FC<{ reportRow: IReportsList, onDelete: () => void }> = (props) => {
  const { reportRow } = props;
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const { state, dispatch } = useGlobalState();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [show, setShow] = React.useState(false);
  const [disable, setDisable] = useState(false);
  const [ProgressBar, setShowProgressBar] = useState(false);

  const useRowStyles = makeStyles((theme: Theme) =>
    createStyles({
      yesButton: {
        background: "green",
        color: "white",
        marginRight: "8px",
        float: 'right',
        borderRadius: 20,
        marginBottom: '0.3cm',
        '&:hover': {
          background: "green",
          color: "white",
        }
      },
      noButton: {
        background: "red",
        color: "white",
        marginBottom: '0.3cm',
        marginRight: "10px",
        borderRadius: 20,
        '&:hover': {
          background: "red",
          color: "white",
        }
      },
      backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
      },
      messageStyle: {
        fontWeight: 'bold',
        marginTop: '5px',
        color: 'white'
      },
      textfield: {
        marginTop: "15px",
        color: "black",
        fontSize: 18,
        fontWeight: 'bold',
      },
    })
  );
  const classes = useRowStyles();
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCancel = () => {
    setOpen(false);
  };

  useEffect(() => {
    if ((reportRow?.userId === state?.userAccessContext?.id) && reportRow?.default_report === 1) {
      setDisable(false);
    } else if (reportRow?.default_report === 0 && reportRow?.userId === state?.userAccessContext?.id) {
      setDisable(false);
    }
    else {
      setDisable(true);
    }
  }, []);

  const closeSnackbar = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setShow(false);
  };

  function Alert(props: AlertProps) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  async function handleOk() {
    dispatch({ type: GlobalStateAction.Busy });
    setOpen(false);
    setShowProgressBar(true);
    try {
      await useDelete("ReportDelete?reportId=" + reportRow.id);
      props.onDelete();
      setShow(true);
      setShowProgressBar(false);
    }
    catch (ex) {
      dispatch({ type: GlobalStateAction.Error, error: ex });
      dispatch({ type: GlobalStateAction.Idle });
    }
    finally {
      dispatch({ type: GlobalStateAction.Idle });
    }
  }

  return (
    <React.Fragment>
      <div>
        <IconButton id="R_Delete_btn" aria-label="delete" style={{ padding: '5px' }}
          onClick={() => { handleClickOpen(); }} disabled={disable}>
          <DeleteForeverIcon style={disable ? { color: "gray", fontSize: 24 } : { color: "red", fontSize: 24 }} />
        </IconButton>
        <Snackbar className="snackBarStyle" open={show} anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }} autoHideDuration={4000} onClose={closeSnackbar}>
          <Alert onClose={closeSnackbar} severity="error" className="alertStyle">
            Report Deleted Successfully!
          </Alert>
        </Snackbar>
      </div>
      <Backdrop className={classes.backdrop} open={ProgressBar}>
        <PuffLoader size={80} color={"white"} speedMultiplier={1} />
      </Backdrop>
      <div>
        <Dialog TransitionComponent={Transition}
          PaperProps={{ style: { borderRadius: 10 } }}
          fullScreen={fullScreen}
          open={open}
          maxWidth={'xs'}
          onClose={handleClose}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitleDelete id="responsive-dialog-title" onClose={handleClose}>
            <Typography variant="h6" gutterBottom className={classes.messageStyle}>
              DELETE MESSAGE
            </Typography>
          </DialogTitleDelete>
          <DialogContent>
            <Typography variant="h6" gutterBottom className={classes.textfield} >
              Are you sure, you want to delete this Report?
            </Typography>
          </DialogContent>
          <DialogActions >
            <Button size="small" id="DR_ok_btn" onClick={handleOk} className={classes.yesButton} color="primary" autoFocus>
              Yes
            </Button>
            <Button size="small" id="DR_cancel_btn" autoFocus onClick={handleCancel} className={classes.noButton} color="primary">
              No
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </React.Fragment >
  );
}
export default DeleteReport